import DocumentHeader from '@/components/DocumentHeader';
import DocumentSchema from '@/components/DocumentSchema';
import { SWS_HOST } from '@/constants';
import type { ProfilePage } from 'schema-dts';
import { useCloudflareImage } from '@/hooks/useCloudflareImage';
import { formatDate } from 'date-fns';

type Props = {
  seoTitle: string;
  seoDescription: string;
  seoImageUrl: string;
  displayName: string;
  publicId: string;
  registerDate: string | null;
};

export const ProfileDocumentHeader = ({
  seoTitle,
  seoDescription,
  seoImageUrl,
  displayName,
  publicId,
  registerDate,
}: Props) => {
  const canonicalUrl = `${SWS_HOST}/community/users/${publicId}`;

  const coverImageUrl = useCloudflareImage({
    src: seoImageUrl,
    width: 1200,
    height: 630,
  });

  return (
    <>
      <DocumentHeader
        title={seoTitle}
        allowCrawlers={true}
        description={seoDescription}
        openGraphImageUrl={coverImageUrl}
      >
        <link rel="canonical" href={canonicalUrl} />
      </DocumentHeader>
      <DocumentSchema<ProfilePage>
        item={{
          '@context': 'https://schema.org',
          '@type': 'ProfilePage',
          dateCreated: registerDate
            ? formatDate(registerDate, "yyyy-MM-dd'T'HH:mm:ssXXX")
            : undefined,
          mainEntity: {
            '@type': 'Person',
            name: displayName,
            alternateName: displayName,
            identifier: publicId,
            description: '',
            image: '',
          },
        }}
      />
    </>
  );
};
