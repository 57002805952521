import { useParams } from '@/hooks/location/useParams';
import NotFound from '@/pages/NotFound';
import { NarrativeCommunityProfile } from '@/pages/Narrative/components/NarrativeCommunityProfile';

export const NarrativeCommunityProfilePage = () => {
  const params = useParams<{ public_id: string | undefined }>();
  const { public_id: publicId } = params;

  if (!publicId) {
    return <NotFound />;
  }

  return <NarrativeCommunityProfile publicId={publicId} />;
};
