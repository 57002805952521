import { ROUTE_DASHBOARD, ROUTE_HOME, ROUTE_WELCOME } from '@/constants/routes';
import useUser from '@/hooks/useUser';

export function useHomeRoute() {
  const { data } = useUser();
  let unauthenticatedHomeRoute: typeof ROUTE_HOME | typeof ROUTE_WELCOME =
    ROUTE_HOME;
  if (RUNTIME_ENV === 'native') {
    unauthenticatedHomeRoute = ROUTE_WELCOME;
  }
  return data.loggedIn ? ROUTE_DASHBOARD : unauthenticatedHomeRoute;
}
