import type { ResultOf } from '@/utilities/gql';
import { gql } from '@/utilities/gql';
import { makeGqlSuspenseQuery } from '@/utilities/gqlQuery';
import { NARRATIVE_CARD_FRAGMENT } from '@/components/NarrativeCard';

const QUERY = gql(
  `
  query getCommunityProfile($ref: ID!) {
    communityProfile(ref: $ref) {
      publicId
      displayName
      role
      disclaimerType
      registerDate
      lastSeen
      slug
      followerCount
      imageUrl
      commentCount
      bio
      links {
        label
        link
      }
      narratives(limit: 12) {
        ...NarrativeCardFields
      }
    }
  }
`,
  [NARRATIVE_CARD_FRAGMENT]
);

export type Result = ResultOf<typeof QUERY>;
export const useCommunityProfile = makeGqlSuspenseQuery(QUERY, {
  tags: ['narrative', 'narrative-user-list'],
});
