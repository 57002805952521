import { useTracking } from 'react-tracking';
import { Suspense } from 'react';
import { ErrorBoundary } from '@sentry/react';

import NotFound from '@/pages/NotFound';
import { useMount } from '@simplywallst/ui-core';

import { Profile } from './components/Profile';
import { ProfileSkeleton } from './components/ProfileSkeleton';

type Props = {
  publicId: string;
};

export const NarrativeCommunityProfile = ({ publicId }: Props) => {
  const { trackEvent } = useTracking();
  useMount(() => {
    trackEvent({
      type: 'track',
      subject: 'page',
      action: 'landed',
      publicId,
    });
  });

  return (
    <ErrorBoundary fallback={<NotFound />}>
      <Suspense fallback={<ProfileSkeleton />}>
        <Profile publicId={publicId} />
      </Suspense>
    </ErrorBoundary>
  );
};
