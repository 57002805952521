import { useTracking } from 'react-tracking';

import { ROUTE_USER_PROFILE } from '@/constants/routes';
import { getPath } from '@/router/utils';
import { Link } from '@simplywallst/ui-core/next';
import LinkOutline from '@simplywallst/ui-core/icons/LinkOutline';
import EditOutline from '@simplywallst/ui-core/icons/EditOutline';

type Props = {
  externalLink: {
    link: string;
    label: string | null;
  } | null;
  isViewingOwnProfile: boolean;
};

export const ProfileExternalLink = ({
  externalLink,
  isViewingOwnProfile,
}: Props) => {
  const { Track, trackEvent } = useTracking({
    subject: 'external-link',
    type: 'track',
  });

  if (!externalLink && isViewingOwnProfile) {
    return (
      <Track>
        <Link
          href={getPath({
            type: ROUTE_USER_PROFILE,
            payload: {},
          })}
          onClick={() => {
            trackEvent({
              modifier: 'add',
              action: 'click',
            });
          }}
          className="group/link no-underline"
        >
          <span className="grid grid-cols-[max-content_min-content] items-center">
            <span className="text-softer group-hover/link:text-soft">
              Add an external link
            </span>
            <EditOutline className="size-6 fill-softer group-hover/link:fill-soft" />
          </span>
        </Link>
      </Track>
    );
  }

  if (!externalLink) {
    return (
      <span className="grid grid-cols-[min-content_max-content] items-center">
        <LinkOutline className="size-6 fill-softer" />
        <span className="text-xs text-softer">No link added</span>
      </span>
    );
  }

  return (
    <Track>
      <Link
        rel="nofollow noopener"
        href={externalLink.link}
        onClick={() => {
          trackEvent({
            action: 'click',
            modifier: 'navigate',
            url: externalLink.link,
          });
        }}
        className="group/link no-underline"
      >
        <span className="grid grid-cols-[min-content_max-content] items-center">
          <LinkOutline className="size-6 fill-soft group-hover/link:fill-solid" />
          <span className="text-soft group-hover/link:text-solid group-hover/link:underline">
            {externalLink?.label || externalLink?.link}
          </span>
        </span>
      </Link>
    </Track>
  );
};
