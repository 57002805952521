import type { ComponentPropsWithoutRef } from 'react';
import { Snowflake as Root } from '@simplywallst/ui-charts/Snowflake';

export const Snowflake = ({
  points,
}: ComponentPropsWithoutRef<typeof Root>) => (
  <Root points={points} width={48}>
    <Root.DotMarkers />
  </Root>
);
