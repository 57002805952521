import Gauge from '@/components/Gauge';
import { toPercentage } from '@/utilities/formatting';

export interface Props {
  portfolioROA: number;
  portfolioName: string;
  marketROA: number;
  marketName: string;
}

const keys = ['portfolio', 'market'];

const ROAGauge = ({
  portfolioROA,
  marketROA,
  portfolioName,
  marketName,
}: Props) => {
  const entities = {
    [keys[0]]: portfolioROA,
    [keys[1]]: marketROA,
  };
  const entityTitles = {
    [keys[0]]: portfolioName,
    [keys[1]]: marketName,
  };
  return (
    <Gauge
      title="ROA"
      keys={keys}
      entityTitles={entityTitles}
      entities={entities}
      formatter={(value: number | null) =>
        value !== null ? toPercentage(value, 1) : 'n/a'
      }
      numTicks={4}
      from={0}
      to={0.1}
    />
  );
};

export default ROAGauge;
