import loadable from '@loadable/component';

const AppVersion =
  RUNTIME_ENV === 'native'
    ? loadable(() => import('./AppVersion'), {
        resolveComponent: (component) => component.AppVersion,
      })
    : () => null;

export { AppVersion };
