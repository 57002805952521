import Gauge from '@/components/Gauge';
import { toPercentage } from '@/utilities/formatting';

export interface Props {
  companyROA: number;
  companyName: string;
  industryROA: number;
  industryName: string;
  labels?: {
    title: string;
    company: string;
    industry: string;
    notAvailable: string;
  };
}

export const ROAGauge = ({
  companyROA,
  industryROA,
  companyName,
  industryName,
  labels = {
    title: 'ROA',
    company: 'Company',
    industry: 'Industry',
    notAvailable: 'n/a',
  },
}: Props) => {
  return (
    <Gauge
      title={labels.title}
      keys={['company', 'industry']}
      keyLabels={{
        company: labels.company,
        industry: labels.industry,
      }}
      entityTitles={{
        company: companyName,
        industry: industryName,
      }}
      entities={{
        company: companyROA,
        industry: industryROA,
      }}
      formatter={(value: number | null) =>
        value !== null ? toPercentage(value, 1) : labels.notAvailable
      }
      numTicks={4}
      from={0}
      to={0.1}
    />
  );
};
