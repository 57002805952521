import { useTracking } from 'react-tracking';

import { Button } from '@simplywallst/ui-core/next';
import DangerSolid from '@simplywallst/ui-core/icons/DangerSolid';
import { useTypeformDrawer } from '@/hooks/useTypeformDrawer';
import { SWS_HOST } from '@/constants';

type Props = {
  publicId: string;
};

export const ReportCommunityProfile = ({ publicId }: Props) => {
  const { trackEvent } = useTracking();

  const makeTypeformPopup = useTypeformDrawer('Cxh0faED', {
    communityProfileUrl: `${SWS_HOST}/community/users/${publicId}`,
  });
  return (
    <Button
      onClick={() => {
        makeTypeformPopup();
        trackEvent({
          modifier: 'report-profile',
          action: 'click',
        });
      }}
      variant="subtle"
      size="medium"
      aria-label="report"
    >
      <DangerSolid />
    </Button>
  );
};
