import { useTracking } from 'react-tracking';
import { format } from 'date-fns';

import { toTruncated } from '@/utilities/formatting';
import { Footer } from '@/components/Footer';
import { NarrativeCardPopulated } from '@/components/NarrativeCard';
import { NarrativeAuthorAvatar } from '@/components/NarrativeAuthor';
import useUser from '@/hooks/useUser';
import { useMe } from '@/components/User/components/Profile/components/CommunityProfile/hooks/useMe';

import { NarrativesEmptyPanel } from '../../../NarrativesEmptyPanel';
import { useCommunityProfile } from '../../hooks/useCommunityProfile';
import { NarrativeInvalidationBoundary } from '../../../NarrativeInvalidationBoundary';
import { ProfileDocumentHeader } from '../ProfileDocumentHeader';
import { ProfileBiography } from '../ProfileBiography';
import { ProfileExternalLink } from '../ProfileExternalLink';
import { ProfileActions } from '../ProfileActions';

type Props = {
  publicId: string;
};

export const Profile = ({ publicId }: Props) => {
  const { Track, trackEvent } = useTracking({
    type: 'track',
    subject: 'narrative',
  });
  const { data: user } = useUser();
  const { data: me } = useMe({}, { enabled: user?.loggedIn });
  const { data } = useCommunityProfile({ ref: publicId });
  const {
    followerCount,
    imageUrl,
    narratives,
    registerDate,
    role,
    commentCount,
    bio,
    links,
  } = data?.communityProfile || {};

  const handleOnClick = (narrative: (typeof narratives)[number]) => {
    const { id, company } = narrative;
    return trackEvent({
      action: 'click',
      modifier: 'view',
      narrativeId: id,
      companyId: company?.id,
      uniqueSymbol: company?.tickerSymbol,
    });
  };

  const displayName = data?.communityProfile?.displayName ?? 'User';
  const memberSince = registerDate ? format(registerDate, 'yyyy') : 'N/A';
  const seoDescription = `Read research and investment narratives by ${displayName} on Simply Wall St. View their profile, areas of focus, and more.`;
  const firstLink = links.length ? links[0] : null;
  const isViewingOwnProfile =
    user.loggedIn &&
    data?.communityProfile?.publicId !== '' &&
    typeof me?.me?.communityProfile?.publicId !== undefined &&
    data?.communityProfile?.publicId === me?.me?.communityProfile?.publicId;

  return (
    <Track>
      <ProfileDocumentHeader
        seoTitle={displayName}
        seoDescription={seoDescription}
        seoImageUrl=""
        displayName={displayName}
        publicId={data?.communityProfile?.publicId}
        registerDate={registerDate}
      />
      <div className="relative grid w-full max-w-screen-xl">
        <div className="mb-x3 bg-surface-1 px-x2 py-x3 lg:mx-x3 lg:gap-x4 lg:rounded-b-x1_5">
          <div className="grid auto-rows-min gap-x2">
            <div className="grid grid-cols-[1fr] items-center gap-x2 lg:grid-cols-[max-content_auto_min-content]">
              <div className="grid grid-cols-[max-content_auto_auto] items-center justify-items-start gap-x1_5 lg:grid-cols-[max-content_auto]">
                <div className="relative grid content-stretch justify-stretch overflow-hidden rounded-full">
                  <NarrativeAuthorAvatar
                    size="lg"
                    owner={{ displayName, imageUrl }}
                  />
                </div>
                <div className="grid grid-rows-[repeat(2,max-content)] gap-x0_25">
                  <div className="text-nowrap text-xl text-solid">
                    {displayName}
                  </div>
                  <div className="text-nowrap text-tiny text-softer">
                    {role}
                  </div>
                </div>
                <div className="justify-self-end sm:block lg:hidden">
                  <ProfileActions
                    publicId={data?.communityProfile?.publicId ?? null}
                    isViewingOwnProfile={isViewingOwnProfile}
                  />
                </div>
              </div>
              <div className="grid grid-cols-[repeat(3,max-content)] items-center gap-x4">
                <div className="grid grid-rows-[repeat(2,max-content)] gap-x0_25">
                  <span className="text-tiny text-softer">Followers</span>
                  <span className="text-xl text-solid">
                    {toTruncated(followerCount, 0)}
                  </span>
                  <span className="sr-only">Total number of followers</span>
                </div>
                <div className="grid grid-rows-[repeat(2,max-content)] gap-x0_25">
                  <span className="text-tiny text-softer">Comments</span>
                  <span className="text-xl text-solid">
                    {toTruncated(commentCount, 0)}
                  </span>
                  <span className="sr-only">Total number of comments</span>
                </div>
                <div className="grid grid-rows-[repeat(2,max-content)] gap-x0_25">
                  <span className="text-tiny text-softer">Member Since</span>
                  <span className="text-xl text-solid">{memberSince}</span>
                  <span className="sr-only">Date joined</span>
                </div>
              </div>
              <div className="hidden justify-self-end lg:block">
                <ProfileActions
                  publicId={data?.communityProfile?.publicId ?? null}
                  isViewingOwnProfile={isViewingOwnProfile}
                />
              </div>
            </div>
            <div className="grid justify-start gap-x2">
              <ProfileBiography
                bio={bio}
                isViewingOwnProfile={isViewingOwnProfile}
              />
              <ProfileExternalLink
                externalLink={firstLink}
                isViewingOwnProfile={isViewingOwnProfile}
              />
            </div>
          </div>
        </div>
        {narratives.length ? (
          <div
            role="list"
            aria-label="narratives-list"
            className="grid w-full grid-cols-[repeat(1,1fr)] gap-4 px-x2 sm:grid-cols-[repeat(2,1fr)] lg:grid-cols-[repeat(3,1fr)] lg:px-x3"
          >
            <NarrativeInvalidationBoundary
              onSetPrimary={({ invalidatePriority }) =>
                invalidatePriority(['narrative-user-list'])
              }
            >
              {narratives.map((narrative) => (
                <div role="listitem" key={narrative.id}>
                  <NarrativeCardPopulated
                    showCompanyIcon={true}
                    narrative={narrative}
                    onClick={() => handleOnClick(narrative)}
                  />
                </div>
              ))}
            </NarrativeInvalidationBoundary>
          </div>
        ) : (
          <NarrativesEmptyPanel>
            There are no narratives available from this user.
          </NarrativesEmptyPanel>
        )}
        <div className="px-x0 lg:px-x3">
          <Footer />
        </div>
      </div>
    </Track>
  );
};
