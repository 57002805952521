import DocumentHeader from '@components/DocumentHeader';
import { Link } from '@simplywallst/ui-core/next';
import {
  ROUTE_STOCKS_HOME,
  ROUTE_DISCOVER_INVESTING_IDEAS,
  ROUTE_PORTFOLIO_DEMO,
} from '@/constants/routes';
import { SWS_SUPPORT } from '@/constants';
import { SUPPORT_EMAIL } from '@/constants';
import GlobalStyle from '@/styled/styled.default';
import { useHomeRoute } from '@/hooks/useHomeRoute';
import { getPath } from '@/router/utils';

const NotFound = () => {
  const homeRoute = useHomeRoute();
  return (
    <div className="grid min-h-screen place-content-center justify-items-center p-4 text-center text-solid">
      <GlobalStyle />
      <DocumentHeader title="Page Not Found" />
      <h2 className="text-balance">Sorry, this page was not found.</h2>
      <h3 className="text-balance text-lg">
        Here are some helpful links instead
      </h3>
      <ul className="grid gap-y-2">
        {/* Routes linked to here must be defined with a specific state side effect in reducers/page.ts, */}
        {/* otherwise they will fail to correctly navigate. */}
        <li>
          <Link
            href={getPath({ type: homeRoute, payload: {} })}
            data-cy-id="home"
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            href={getPath({
              type: ROUTE_DISCOVER_INVESTING_IDEAS,
              payload: {},
            })}
            data-cy-id="browse-investingideas"
          >
            Discover investing ideas
          </Link>
        </li>
        <li>
          <Link
            href={getPath({ type: ROUTE_STOCKS_HOME, payload: {} })}
            data-cy-id="browse-companies"
          >
            Browse all stocks
          </Link>
        </li>
        <li>
          <Link
            href={getPath({ type: ROUTE_PORTFOLIO_DEMO, payload: {} })}
            data-cy-id="view-demo-portfolio"
          >
            View demo portfolio
          </Link>
        </li>
        <li>
          <Link href={SWS_SUPPORT}>Learning centre</Link>
        </li>
      </ul>

      <p className="mt-5 max-w-[40ch] text-balance text-xs">
        <span>If you think you&rsquo;re seeing this page in error </span>
        <a href={SUPPORT_EMAIL} rel="nofollow noreferrer" target="_blank">
          send us an email
        </a>
        <span> to let us know!</span>
      </p>
    </div>
  );
};

export default NotFound;
