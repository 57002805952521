import { useTracking } from 'react-tracking';

import { Snowflake } from '@simplywallst/ui-charts/Snowflake';

import {
  COMPANY_VALUE_ANCHOR,
  COMPANY_FUTURE_ANCHOR,
  COMPANY_PAST_ANCHOR,
  COMPANY_HEALTH_ANCHOR,
  COMPANY_DIVIDEND_ANCHOR,
} from '@/constants/anchors';

interface Props {
  points: [number, number, number, number, number];
  onClick?: (anchor: string) => void;
}

export const Default = ({ points, onClick }: Props) => {
  const { Track, trackEvent } = useTracking({
    type: 'track',
    subject: 'snowflake',
  });

  const handleClick = (anchor: string) => {
    trackEvent({
      action: 'click',
    });
    onClick?.(anchor);
  };
  return (
    <Track>
      <Snowflake points={points} width={144}>
        <Snowflake.Labels />
        <Snowflake.Segments>
          <Snowflake.DividendSegment
            onClick={() => handleClick(COMPANY_DIVIDEND_ANCHOR)}
          />
          <Snowflake.HealthSegment
            onClick={() => handleClick(COMPANY_HEALTH_ANCHOR)}
          />
          <Snowflake.PastSegment
            onClick={() => handleClick(COMPANY_PAST_ANCHOR)}
          />
          <Snowflake.FutureSegment
            onClick={() => handleClick(COMPANY_FUTURE_ANCHOR)}
          />
          <Snowflake.ValueSegment
            onClick={() => handleClick(COMPANY_VALUE_ANCHOR)}
          />
        </Snowflake.Segments>
      </Snowflake>
    </Track>
  );
};
