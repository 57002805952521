import { useTracking } from 'react-tracking';

import { ROUTE_USER_PROFILE } from '@/constants/routes';
import { getPath } from '@/router/utils';
import { Button } from '@simplywallst/ui-core/next';

import { ReportCommunityProfile } from '../ReportCommunityProfile';

type Props = {
  publicId: string | null;
  isViewingOwnProfile: boolean;
};

export const ProfileActions = ({ publicId, isViewingOwnProfile }: Props) => {
  const { Track, trackEvent } = useTracking({
    subject: 'actions',
    type: 'track',
  });

  if (isViewingOwnProfile) {
    return (
      <Track>
        <Button
          variant="outline"
          href={getPath({
            type: ROUTE_USER_PROFILE,
            payload: {},
          })}
          onClick={() => {
            trackEvent({
              modifier: 'edit-profile',
              action: 'click',
            });
          }}
        >
          Edit Profile
        </Button>
      </Track>
    );
  }

  if (publicId) {
    return (
      <Track>
        <ReportCommunityProfile publicId={publicId} />
      </Track>
    );
  }

  return null;
};
