import { useTracking } from 'react-tracking';

import { ROUTE_USER_PROFILE } from '@/constants/routes';
import { getPath } from '@/router/utils';
import EditOutline from '@simplywallst/ui-core/icons/EditOutline';
import { Link } from '@simplywallst/ui-core/next';

type Props = {
  bio: string | null;
  isViewingOwnProfile: boolean;
};

export const ProfileBiography = ({ bio, isViewingOwnProfile }: Props) => {
  const { Track, trackEvent } = useTracking({
    subject: 'biography',
    type: 'track',
  });

  if (!bio && isViewingOwnProfile) {
    return (
      <Track>
        <Link
          href={getPath({
            type: ROUTE_USER_PROFILE,
            payload: {},
          })}
          onClick={() => {
            trackEvent({
              action: 'click',
              modifier: 'add',
            });
          }}
          className="group/link no-underline"
        >
          <span className="grid grid-cols-[max-content_min-content]">
            <span className="text-softer group-hover/link:text-soft">
              Add a bio to let others know more about you
            </span>
            <EditOutline className="size-6 fill-softer group-hover/link:fill-soft" />
          </span>
        </Link>
      </Track>
    );
  }

  if (!bio) {
    return <p className="mb-0 text-xs text-softer">No bio added yet</p>;
  }

  return <p className="mb-0 text-xs text-solid">{bio}</p>;
};
