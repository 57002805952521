import { NarrativeCardLoading } from '@/components/NarrativeCard';

const PLACEHOLDER_ITEMS = new Array(4).fill(0);
export const ProfileSkeleton = () => {
  return (
    <div className="relative grid w-full max-w-screen-xl">
      <div className="mb-x3 bg-surface-1 px-x2 py-x3 lg:mx-x3  lg:gap-x4 lg:rounded-b-x1_5">
        <div className="grid auto-rows-min gap-x2">
          <div className="grid grid-cols-[1fr] gap-x2 lg:grid-cols-[max-content_max-content]">
            <div className="grid grid-cols-[max-content_auto] items-center justify-items-start gap-x1_5">
              <div className="relative grid content-stretch justify-stretch overflow-hidden rounded-full">
                <div className="bones relative grid size-14 content-center items-center justify-center overflow-hidden rounded-full text-lg" />
              </div>
              <div className="grid grid-rows-[repeat(2,max-content)] gap-x0_25">
                <div className="bones h-x3 w-x16" />
                <div className="bones h-x2 w-x12" />
              </div>
            </div>
            <div className="grid grid-cols-[repeat(3,max-content)] items-center gap-x4">
              <div className="grid grid-rows-[repeat(2,max-content)] gap-x0_25">
                <div className="bones h-x3 w-x8" />
                <div className="bones h-x2 w-x6" />
              </div>
              <div className="grid grid-rows-[repeat(2,max-content)] gap-x0_25">
                <div className="bones h-x3 w-x8" />
                <div className="bones h-x2 w-x6" />
              </div>
              <div className="grid grid-rows-[repeat(2,max-content)] gap-x0_25">
                <div className="bones h-x3 w-x8" />
                <div className="bones h-x2 w-x6" />
              </div>
            </div>
          </div>
          <div className="bones h-x2 w-[200px]" />
          <div className="align-center grid grid-cols-[min-content_min-content] items-center">
            <div className="bones h-x4 w-x16" />
          </div>
        </div>
      </div>
      <div
        role="list"
        className="grid w-full grid-cols-[repeat(1,1fr)] gap-4 px-x2 sm:grid-cols-[repeat(2,1fr)] lg:grid-cols-[repeat(3,1fr)] lg:px-x3"
      >
        {PLACEHOLDER_ITEMS.map((_, index) => (
          <div role="listitem" key={index}>
            <NarrativeCardLoading showCompanyIcon key={index} />
          </div>
        ))}
      </div>
    </div>
  );
};
