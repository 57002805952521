import type { ReactNode } from 'react';
import NarrativesEmptyPanelCard from './narratives-empty-panel-card.svg';

type Props = {
  children: ReactNode;
};

export const NarrativesEmptyPanel = ({ children }: Props) => {
  return (
    <div
      className="relative grid w-full justify-center p-4 md:p-12"
      role="alert"
    >
      <div
        className="grid grid-cols-[repeat(2,minmax(50px,164px))] grid-rows-[repeat(2,auto)] gap-1
        opacity-50 md:grid-cols-[repeat(2,164px)]
        [&>*]:h-auto [&>*]:w-full
        "
      >
        <NarrativesEmptyPanelCard />
        <NarrativesEmptyPanelCard />
        <NarrativesEmptyPanelCard />
        <NarrativesEmptyPanelCard />
      </div>

      <div className="absolute inset-0 grid h-full items-center justify-center text-center text-base text-softer">
        <div className="max-w-[300px] text-pretty p-8">{children}</div>
      </div>
    </div>
  );
};
